import { CADropdown } from '@careacademy/ca-design-system'
import { defineComponent, type PropType } from 'vue'

import type { ActionElements } from '@/javascript/interfaces'
import { on } from '@/javascript/vuejs/helpers/emitEventHandler'

import { stringToNumber } from '../packages/utils'

/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
const emits = { action: (action: string, id?: number | string, type?: string): boolean => true }

export default defineComponent({
	name: 'DataTableActions',

	props: {
		...on(emits),

		actions: {
			required: true,
			type: Array as PropType<ActionElements[]>,
		},

		id: {
			required: true,
			type: [Number, String] as PropType<number | string>,
		},
	},

	emits,

	computed: {
		convertedId(): number {
			return stringToNumber(this.id)
		},
	},

	methods: {
		handleOnActionClick(event: Event, action: string, type?: string): void {
			event.stopPropagation()
			this.$emit('action', action, this.convertedId, type)
		},
	},

	render() {
		return (
			<CADropdown
				buttonDataTest="folder-options-dropdown"
				buttonStyle="text"
				id="folder-options"
				onClick={(event: Event): void => event.stopPropagation()}
			>
				<template slot="button">Options</template>

				<ul role="menu">
					{this.actions?.map(action =>
						<a
							class={[`ca-dropdown__menu-item`, action.class]}
							data-test={action.dataTest}
							href={action.link ? action.link : 'javascript:void(0)'}
							role="menuitem"
							v-tooltip={action.tooltip}
							onClick={(event): void => this.handleOnActionClick(event, action.text ?? '', action.type)}
						>
							{action.text}
						</a>
					)}
				</ul>
			</CADropdown>
		)
	},
})
