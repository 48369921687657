import type { ActionElements } from '@/javascript/interfaces'
import {
	CONTENT_PACKAGE,
	CONTENT_PACKAGE_FOLDER,
	type ContentPackageFolder,
} from '@/javascript/vuejs/services/packages/interfaces'

import type { Folder } from '../shared/TreeView'

export function stringToNumber(id: number | string): number {
	return typeof id === 'string' ? parseInt(id) : id
}

export function toFolder(folder: ContentPackageFolder): Folder {
	return {
		children: folder.children?.map(toFolder),
		data: {
			actions: showActions(folder),
			id: folder.id,
			type: folder.type,
		},
		id: `${folder.id}-${folder.type}`,
		state: {
			checked: false,
			visible: true,
		},
		text: folder.name,
		type: folder.type,
	}
}

function showActions(folder: ContentPackageFolder): ActionElements[] {
	return folder.type === 'content_package_folder' ? [
		{
			dataTest: 'package-action-create-new-folder',
			icon: 'fa-folder-add',
			id: folder.id,
			link: undefined,
			text: 'Create',
			tooltip: 'Create New Folder',
			type: CONTENT_PACKAGE_FOLDER,
		},
		{
			dataTest: 'package-action-rename',
			icon: 'fa-rename-box',
			id: folder.id,
			link: undefined,
			text: 'Rename',
			tooltip: 'Rename Folder',
			type: CONTENT_PACKAGE_FOLDER,
		},
		{
			dataTest: 'package-action-move',
			icon: 'fa-folder-move',
			id: folder.id,
			link: undefined,
			text: 'Move',
			tooltip: 'Move to Folder',
			type: CONTENT_PACKAGE_FOLDER,
		},
		{
			dataTest: 'package-action-delete',
			icon: 'fa-folder-remove',
			id: folder.id,
			link: undefined,
			text: 'Delete',
			tooltip: 'Delete Folder',
			type: CONTENT_PACKAGE_FOLDER,
		},
	] : [
		{
			dataTest: 'package-action-edit',
			icon: 'fa-edit-outline',
			id: folder.id,
			link: `/packages/${folder.id}/edit`,
			text: 'Edit',
			tooltip: 'Edit Package',
			type: CONTENT_PACKAGE,
		},
		{
			dataTest: 'package-action-move',
			icon: 'fa-folder-move',
			id: folder.id,
			link: undefined,
			text: 'Move',
			tooltip: 'Move into Folder',
			type: CONTENT_PACKAGE_FOLDER,
		},
	]
}
