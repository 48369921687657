export const CONTENT_PACKAGE = 'content_package'
export const CONTENT_PACKAGE_FOLDER = 'content_package_folder'

export interface ContentPackage {
	assignedCourses: number
	certifications: number
	copyPath: string
	cumulativeCourses: number
	editPath: string
	hours: string | null
	id: number
	isBuildingBlock: boolean
	name: string
	subPackages: string
	type: 'content_package'
}

export interface ContentPackageFolder {
	children?: ContentPackageFolder[]
	editPath?: string
	hasChildren?: boolean
	id: number
	level?: number
	level_name?: string
	name: string
	parent_folder_id?: number
	type: PackageType
}

export interface Package {
	id: number
	isAddOn: boolean
	name: string
}

export interface Packages {
	packageName: string
	packages: Package[]
}

export type PackageType = 'content_package_folder' | 'content_package'
