/**
 * Pagination function:
 *
 * @example paginator(items, 2, 2)
 * @param {array} items
 * @param {number} currentPage
 * @param {number} perPageItems
 * @returns {object}
 */

export function paginator<T>(items: T[], currentPage: number, perPage: number): PaginationData<T> {
	const totalPages = Math.ceil(items.length / perPage)
	const page = Math.min(totalPages, currentPage)
	const offset = (page - 1) * perPage
	const paginatedItems = items.slice(offset).slice(0, perPage)

	return {
		data: paginatedItems,
		firstItem: offset + 1,
		lastItem: offset + paginatedItems.length,
		nextPage: page < totalPages ? page + 1 : null,
		page: page,
		perPage: perPage,
		previousPage: page > 1 ? page - 1 : null,
		totalItems: items.length,
		totalPages: totalPages,
	}
}

export interface PaginationData<T> {
	data: T[]
	firstItem: number
	lastItem: number
	nextPage: number | null
	page: number
	perPage: number
	previousPage: number | null
	totalItems: number
	totalPages: number
}
